<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <el-form-item label="Единица измерения" prop="unit_id">
      <element-scale-unit-select
        v-model="scale.unit_id"
        :current-unit="scale.unit"
      ></element-scale-unit-select>
    </el-form-item>

    <el-form-item label="Целевые значения">
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="target_a">
            <div>Min</div>
            <el-date-picker
              v-model="scale.target_a"
              :disabled="disabled"
              size="small"
              :picker-options="{firstDayOfWeek: 1}"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd.MM.yyyy"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_b">
            <div>Цель</div>
            <el-date-picker
              v-model="scale.target_b"
              :disabled="disabled"
              size="small"
              :picker-options="{firstDayOfWeek: 1}"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd.MM.yyyy"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_c">
            <div>Max</div>
            <el-date-picker
              v-model="scale.target_c"
              :disabled="disabled"
              size="small"
              :picker-options="{firstDayOfWeek: 1}"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd.MM.yyyy"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
      label="Выплаты за достижения, %"
    >
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="pay_a">
            <el-input type="number" v-model="scale.pay_a" :disabled="disabled">
              <template slot="prepend">Min</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_b">
            <el-input type="number" v-model="scale.pay_b" :disabled="disabled">
              <template slot="prepend">Target</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_c">
            <el-input type="number" v-model="scale.pay_c" :disabled="disabled">
              <template slot="prepend">Max</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
      label="Факт"
      prop="fact"
    >
      <el-date-picker
        v-model="scale.fact"
        :disabled="disabled"
        size="small"
        :picker-options="{firstDayOfWeek: 1}"
        type="date"
        value-format="yyyy-MM-dd"
        format="dd.MM.yyyy"
      >
      </el-date-picker>
    </el-form-item>
  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import {mapGetters} from "vuex";
import ElementScaleUnitSelect from "@/components/filters/elements/ElementScaleUnitSelect.vue";

export default {
  name: "library-edit-form",
  mixins: [dynamicScaleComponent],
  components: {ElementScaleUnitSelect},

  props: {},

  computed: {},

  watch: {},

  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    })
  },

  data() {
    return {
      scale:{
        unit: null,
        unit_id: null,

        target_a: null,
        target_b: null,
        target_c: null,
        pay_a: null,
        pay_b: null,
        pay_c: null,
        fact: null,
        bonus: null,
      },

      rules: {},

    }
  },

  methods: {

  }
}
</script>

<style>

</style>